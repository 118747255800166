@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply rounded-full border-2 border-primary bg-primary px-6 py-2 text-center text-lg text-primaryblack duration-700 ease-in-out hover:border-primaryblack hover:bg-primaryblack hover:text-primary;
  }
  .btn-secondary-transparent {
    @apply rounded-full border-2 border-primary bg-transparent px-6 py-2 text-center text-lg text-white duration-700 ease-in-out hover:border-white;
  }
  .btn-secondary-white-bg {
    @apply rounded-full border-2 border-primary bg-white px-6 py-2 text-center text-lg text-primaryblack duration-700 ease-in-out hover:border-primaryblack;
  }
  .nav-link {
    @apply relative py-2 after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:scale-x-0 after:bg-primary after:opacity-0 after:duration-300 hover:after:scale-x-100 hover:after:opacity-100 focus:outline-none focus:after:scale-x-100 focus:after:opacity-100 laptop:px-2;
  }
}

html {
  text-wrap: pretty;
}

body {
  overscroll-behavior-y: none;
}

a {
  word-break: break-word;
}

::-moz-selection {
  color: white;
  background: #ff655c;
}

::selection {
  color: white;
  background: #ff655c;
}

.mediaEmbed {
  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
  }
}
